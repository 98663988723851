<template>
  <v-navigation-drawer permanent clipped app v-model="selectedItem" color="sidenav">
    <template>
      <div class="ma-8">
        <v-img :aspect-ratio="15 / 6" src="@/assets/logo.png" />
      </div>
    </template>
    <br>
    <v-list>
      <!-- <v-list-item-group active-class="bg-active" v-model="model">
        <v-list-item v-for="item in validtos" :key="item.name" link :to="item.route" class="font-weight-bold">
          <v-list-item-icon>
            <v-icon v-text="item.icon" ></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-name v-text="item.name" class=""></v-list-item-name>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group> -->
      <v-list-group v-for="item in validtos" :key="item.id" v-model="item.active"  :append-icon="''">
        <div slot="activator" class="d-flex justify-space-between" style="width: 100%;">
          <!-- <v-list-item v-if="item.route" link :to="item.route" class="item-link"></v-list-item>
          <v-list-item-content>
            <v-list-item-title v-text="item.name"></v-list-item-title>
          </v-list-item-content> -->
          <v-list-item link :to="item.route" class="font-weight-bold"  active-class="bg-active" >
            <v-list-item-icon>
              <v-icon v-text="item.icon"></v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-text="item.name" class=""></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </div>

        <v-list-item v-for="childItem in item.children" :key="childItem.name" link :to="childItem.route" exact
          class="subitem">
          <v-list-item-content>
            <v-list-item-title v-text="childItem.name" class="pl-15"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
// @ is an alias to /src
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    // drawer: { type: Boolean, default: true }
  },
  computed: {
    ...mapGetters(["userSession"]),
  },
  name: "Sidebar",
  components: {},
  data: () => ({
    selectedItem: 1,
    validtos: [],
    model: 0,
    // isSelectedChild: this.drawer,
    items: [
      {
        name: "Resumen",
        icon: "mdi-chart-line",
        active: false,
        color: "",
        route: "/",
        id: "home",
        roles: ["admin"],
      },
      {
        name: "Datos",
        icon: "mdi-soccer",
        color: "",
        route: "/datos",
        id: "datos",
        active: false,
        children: [
          {
            name: 'Equipos',
            route: '/datos',
            id: 'equipos-index',
          }, {
            name: 'Grupos',
            route: '/datos/grupos',
            id: 'grupos-index,'
          },
          {
            name: 'Partidos',
            route: '/datos/partidos',
            id: 'partidos-index',
          }
        ],
        roles: ["admin"],
      },
      {
        name: "Polla callejera",
        icon: "mdi-ticket-percent-outline",
        color: "",
        id: "pollacallejera-index",
        route: '/polla-callejera',
        active: false,
        children: [
          {
            name: 'Polla',
            route: '/polla-callejera/',
            id: 'pollacallejera-index',
          },
          {
            name: 'Posiciones',
            route: '/polla-callejera/posiciones',
            id: 'pollacallejera-posiciones',
          },
           {
            name: 'Ventas',
            route: '/polla-callejera/ventas',
            id: 'pollacallejera-ventas',
          }
        ],
        roles: ["admin"],
      },
      {
        name: "Polla de patas",
        icon: "mdi-account-supervisor",
        color: "",
        id: 'pollapatas',
        active: false,
        route: '/polla-patas',
        roles: ["admin"],
      },
      {
        name: "Usuarios",
        icon: "mdi-account",
        color: "",
        id: "usuarios",
        route: '/usuarios',
        active: false,
        roles: ["admin"],
      },
      {
        name: "Noticias",
        icon: "mdi-note-text-outline",
        color: "",
        id: 'noticias',
        route: '/noticias',
        active: false,
        roles: ["admin"],
      },
       {
        name: "Cupones",
        icon: "mdi-ticket-outline",
        color: "",
        id: 'cupones',
        route: '/cupones',
        active: false,
        roles: ["admin"],
      },
      {
        name: "Resumen",
        icon: "mdi-chart-line",
        active: false,
        color: "",
        route: "/empresa-resumen",
        id: "compay-home",
        roles: ["company"],
      },
      {
        name: "Usuarios",
        icon: "mdi-account",
        active: false,
        color: "",
        route: "/empresa-usuarios",
        id: "company-users",
        roles: ["company"],
      }, {
        name: "Premios",
        icon: "mdi-trophy",
        active: false,
        color: "",
        route: "/empresa-premios",
        id: "company-awards",
        roles: ["company"],
      },
      {
        name: "Puntaje",
        icon: "mdi-receipt-text-plus-outline",
        color: "",
        id: 'puntaje',
        route: '/puntaje',
        active: false,
        roles: ["admin","company"],
      },
    ],
    right: null,
  }),
  watch: {
    $to(val) {
      this.setActiveto();
    },
    // drawer (val) {recepcionista-moquegua@email.com
    //   this.isSelectedChild = val
    // },
    // isSelectedChild (val) {
    //   this.$emit('chageDrawer', val)
    // }
  },
  methods: {
    ...mapActions(["logout"]),
    // setActiveto() {
    //   let ito = this.$to;
    //   this.validtos.forEach((element) => {
    //     if (element.id === ito.meta.id) {
    //       element.color = "#479b25";
    //     } else {
    //       element.color = "";
    //     }
    //   });
    // },
    logOut() {
      this.logout().then(() => {
        this.$tor.push("/login");
      });
    },
    byRole(role) {
      let orole = role.map(e=> e.name);
      return function (value) {
        if (value && value.roles) {
          let valid = !!value.roles.some(r=> orole.indexOf(r) >= 0);
          return valid;
          // return true;
        }
        return false;
      };
    },
  },
  created() {
    this.validtos = this.items.filter(this.byRole(this.userSession.roles));
    // this.validtos = this.items;
    // this.setActiveto();
  },
};
</script>

<style scoped lang="scss">
.item-link {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

/* .subitem:hover{
   background-color: transparent;
 } */
.v-list-item {
  color: #083C5F !important;
}


// .v-list-group__header .v-list-item .v-list-item--link {
//     padding: 0px 0px !important;
// }

.bg-active {
  color: white !important;
  border-top-right-radius: 40px 40px;
  border-bottom-right-radius: 40px 40px;
  background-color: #299F7E;
}

.item-head {
  color: #083C5F;
}

</style>
