<template>
  <v-app>
    <Sidebar />
    <Header />
    <v-main>
      <v-container fluid>
        <v-row>
          <v-col cols="12" class="px-0">
            <BreadCrumbs />
          </v-col>
        </v-row>
        <router-view></router-view>
      </v-container>
    </v-main>

  </v-app>
</template>

<script>
import Sidebar from "@/components/layout/SideBar.vue";
import Header from "@/components/layout/Header.vue";
import BreadCrumbs from '@/components/layout/BreadCrumbs.vue';
export default {
  name: "home",
  components: {
    Sidebar,
    Header,
    BreadCrumbs
  },
  computed: {
  },
};
</script>

<style>
</style>