<template>
    <div>
        <v-breadcrumbs :items="breadCrumbs">
            <template v-slot:item="{ item }">
                <v-breadcrumbs-item :to="item.to" class="text-h5 crumb-item font-weight-bold" :disabled="item.disabled" exact
                style="font-family: Exo 2;color:#083C5F;"
                >
                    {{ item.text }}
                </v-breadcrumbs-item>
            </template>
            <template v-slot:divider>
                <v-icon>mdi-chevron-right</v-icon>
            </template>

        </v-breadcrumbs>
    </div>

</template>

<script>

export default {
    name: "BreadCrumbs",
    components: {
    },
    computed: {
        breadCrumbs() {
            if (typeof this.$route.meta.breadCrumb === "function") {
                return this.$route.meta.breadCrumb.call(this, this.$route);
            }
            return this.$route.meta.breadCrumb;
        }
    },
    data: () => ({
        items: [],
    }),
};
</script>

<style>
</style>