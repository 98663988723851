<template>
   <v-app-bar app color="primary2" clipped-left>
     <v-spacer></v-spacer>
      <div class="mr-4">
         <div class="subtitle-1 text-right white--text">{{userSession.name}}</div>

      </div>
       <v-menu
        bottom
        origin="center center"
        transition="scale-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-avatar
            v-bind="attrs"
            v-on="on"
            color="white"
            size="44">
          <!-- <span class="white--text overline">TC</span> -->
        </v-avatar>
      </template>

      <v-list>
        <v-list-item>
          <v-list-item-title @click="logOut()">Cerrar sesión</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    </v-app-bar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Header",
  data: () => ({
  }),
  mounted() {
    // console.log(this.$route);
  },
  computed : {
     ...mapGetters(["userSession"]),
  },
  methods: {
    ...mapActions(["logout"]),
    logOut() {
      this.logout().then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>

<style scoped>
</style>